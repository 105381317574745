(function () {
  'use strict';

  angular
  .module('neo.home.refereeNewInvoice.invoicestrimestral')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.invoicestrimestral', {
      url: '/referee-new-invoice/invoicestrimestral',
     
          templateUrl: 'home/referee-new-invoice/invoices-trimestral/invoices-trimestral.tpl.html',
          controller: 'invoicestrimestral',
          controllerAs: 'vm',
        resolve:{
          townHalls: function (Ajuntaments) {
            return Ajuntaments.query().$promise;
          }
        }
       
    });
  }
}());


